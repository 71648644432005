import './polyfills';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PortalAppModule } from './app';
import '@angular/compiler'; // import compiler for dynamic templates compilation (e.g. for live state)
// depending on the env mode, enable prod mode or add debugging modules
// @ts-ignore
if (process.env.ISPROD) {
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(PortalAppModule).then(ref => {
    const w = (window as { [key: string]: any });
    // Ensure Angular destroys itself on hot reloads.
    if (w['ngRef']) {
        w['ngRef'].destroy();
    }
    w['ngRef'] = ref;

    // Otherwise, log the boot error
}).catch(err => console.error(err));
