import { Component, HostBinding, HostListener, ViewContainerRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { APP_VIEWCONTAINERREF_CHANGED, EmitterService, lazyModuleLoadingChanged } from '@myia/ngx-core';
import { PWAUpdateService, StatusBarManager } from '@shared';

const APP_BUSY_STATE = 'APP_BUSY';

@Component({
    selector: 'portal-app',
    styleUrls: ['./portalApp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="portalAppRoot" trackMouseFocus>
            <div class="loadingModule" *ngIf="loadingModule">{{'Loading'|trans}}</div>
            <div class="portalViewContent" [ngClass]="{loading: loadingModule}">
                <router-outlet></router-outlet>
            </div>
            <context-menu-holder></context-menu-holder>
            <status-bar *ngIf="loadingModule"></status-bar>
        </div>
        <ng-template let-dialog dialogIdentifier="confirmationDialog">
            <div class="dialogMessage">{{dialog.dialogData.message || dialog.dialogData}}</div>
            <div class="dialogButtons">
                <span class="spacer"></span>
                <button type="button" class="dialogButtonPrimary dialogButton" (click)="dialog.close(true)">{{(dialog.dialogData.btnTrue || 'Yes') | trans}}</button>
                <button type="button" class="dialogButtonSecondary dialogButton" (click)="dialog.close()">{{(dialog.dialogData.btnFalse || 'No') | trans}}</button>
            </div>
        </ng-template>
    `
})
export class PortalAppComponent {

    @HostBinding('class.appEl') hostClass = true;

    loadingModule = false;

    // inject PWAUpdateService to check new app versions
    constructor(private _changeDetectorRef: ChangeDetectorRef, vcRef: ViewContainerRef, pwaUpdateService: PWAUpdateService, private _router: Router, private _statusBarManager: StatusBarManager) {
        // notify other components about root app view container (e.g. ModalDialog, GestureModule)
        EmitterService.getValueChangedEvent<ViewContainerRef>(APP_VIEWCONTAINERREF_CHANGED).next(vcRef);
        lazyModuleLoadingChanged(this._router, 'portal').subscribe(loading => {
            this.loadingModule = loading;
            this._statusBarManager.showBusyLine(loading, APP_BUSY_STATE);
            this._changeDetectorRef.detectChanges();
        });
    }

    @HostListener('dragenter', ['$event'])
    onDragEnterBody($event: any) {
        return this.cancelDragOperation($event);
    }

    @HostListener('dragover', ['$event'])
    onDragOverBody($event: any) {
        return this.cancelDragOperation($event);
    }

    private cancelDragOperation($event: any) {
        $event.stopPropagation();
        $event.preventDefault();
        $event.dataTransfer.dropEffect = 'none'; // disable drop by default
        return false;
    }
}
