import { Routes } from '@angular/router';
import { AccessDeniedRoutePath, AccountRoutePath, ActivateRoutePath, DashboardRoutePath, EventRoutePath, GameRoutePath, LoginRoutePath, LogoutRoutePath, ResetPasswordRoutePath, SignUpRoutePath } from '@shared';
import { PortalAppRedirectComponent } from './portalApp.Redirect';

export const MainAppRoutes: Routes = [
    {
        path: AccessDeniedRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'accessDenied.module' */'./pages/accessDenied/accessDenied.Module').then(m => m.AccessDeniedModule)
    },
    {
        path: AccountRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'account.module' */'./pages/account/account.Module').then(m => m.AccountModule),
    },
    {
        path: ActivateRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'activate.module' */'./pages/activate/activate.Module').then(m => m.ActivateModule),
    },
    {
        path: DashboardRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'dashboard.module' */'./pages/dashboard/dashboard.Module').then(m => m.DashboardModule),
    },
    {
        path: `${EventRoutePath}/:eventId`,
        loadChildren: () => import(/* webpackChunkName: 'event.module' */'./pages/event/event.Module').then(m => m.EventModule),
    },
    {
        path: LoginRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'login.module' */'./pages/login/login.Module').then(m => m.LoginModule),
    },
    {
        path: LogoutRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'logout.module' */'./pages/logout/logout.Module').then(m => m.LogoutModule),
    },
    {
        path: ResetPasswordRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'resetPassword.module' */'./pages/resetPassword/resetPassword.Module').then(m => m.ResetPasswordModule),
    },
    {
        path: SignUpRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'signup.module' */'./pages/signup/signup.Module').then(m => m.SignUpModule),
    },
    // Matching game route - no Auth - temporary route
    {
        path: `${GameRoutePath}/:eventId`,
        loadChildren: () => import(/* webpackChunkName: 'eventGame.module' */'./pages/event/game/eventGame.Module').then(m => m.EventGameModule),
    },
    // redirect all other urls to dashboard
    {
        path: '**',
        component: PortalAppRedirectComponent
    }
];
